import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core'
import { Router } from '@angular/router'

export interface HoverMenuItem {
  name: string
  url?: string
  visible?: boolean
}

@Directive({
  selector: '[appHoverMenu]',
})
export class HoverMenuDirective implements OnDestroy, AfterViewInit {
  @Input() appHoverMenu: HoverMenuItem[]
  @Input() hoverMenuHidden: boolean = false

  private menuElement: HTMLElement | null = null
  private static activeMenuElement: HTMLElement | null = null
  private static listenersInitialized = false
  private static outsideClickListener: any
  private static mouseLeaveListener: any
  private static mouseLeaveOutsideMenuListener: any

  private menuContainer: HTMLElement | null = null

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router
  ) {
    if (!HoverMenuDirective.listenersInitialized) {
      this.initializeDocumentClickListener()
      HoverMenuDirective.listenersInitialized = true
    }
  }

  ngAfterViewInit(): void {
    // Create a menuContainer around menus so if mouseLeave out of it ensure to remove active menu
    setTimeout(() => {
      this.createMenuContainer()
    })
  }

  @HostListener('mouseenter') onMouseEnter() {
    HoverMenuDirective.removeActiveMenu()
    if (!this.hoverMenuHidden) {
      this.createMenu()
      this.positionMenu()
    }
  }

  private initializeDocumentClickListener() {
    // Listen for clicks anywhere in the document
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      if (
        HoverMenuDirective.activeMenuElement &&
        !this.el.nativeElement.contains(event.target) &&
        !HoverMenuDirective.activeMenuElement.contains(event.target as Node)
      ) {
        HoverMenuDirective.removeActiveMenu()
      }
    })
  }

  private createMenuContainer() {
    const hostPos = this.el.nativeElement.getBoundingClientRect()
    this.menuContainer = this.renderer.createElement('div')
    this.renderer.addClass(this.menuContainer, 'menu-container')
    this.renderer.setStyle(this.menuContainer, 'position', 'fixed')
    this.renderer.setStyle(this.menuContainer, 'top', '0')
    this.renderer.setStyle(this.menuContainer, 'left', '110px')
    this.renderer.setStyle(this.menuContainer, 'width', '5px')
    this.renderer.setStyle(this.menuContainer, 'bottom', '0')
    HoverMenuDirective.mouseLeaveOutsideMenuListener = this.renderer.listen(
      this.menuContainer,
      'mouseenter',
      () => {
        setTimeout(() => {
          HoverMenuDirective.removeActiveMenu()
        }, 100)
      }
    )
  }

  createMenu() {
    this.menuElement = this.renderer.createElement('div')
    this.renderer.addClass(this.menuElement, 'menu-overlay')
    // Fill the menu with items based on appHoverMenu
    this.appHoverMenu.forEach(item => {
      if (item.visible !== false) {
        const itemElement = this.renderer.createElement('div')
        this.renderer.addClass(itemElement, 'clickable')
        const textNode = this.renderer.createText(item.name)
        this.renderer.appendChild(itemElement, textNode)
        if (item.url) {
          HoverMenuDirective.outsideClickListener = this.renderer.listen(
            itemElement,
            'click',
            () => {
              this.router.navigateByUrl(item.url)
              this.removeMenu()
            }
          )
        }
        this.renderer.appendChild(this.menuElement, itemElement)
      }
    })
    this.renderer.appendChild(this.menuContainer, this.menuElement)
    this.renderer.appendChild(document.body, this.menuContainer)
    this.renderer.appendChild(document.body, this.menuElement)
    HoverMenuDirective.activeMenuElement = this.menuElement

    // On mouse leave remove the active menu
    HoverMenuDirective.mouseLeaveListener = this.renderer.listen(
      this.menuElement,
      'mouseleave',
      () => {
        setTimeout(() => {
          HoverMenuDirective.removeActiveMenu()
        }, 100)
      }
    )
  }

  positionMenu() {
    const hostPos = this.el.nativeElement.getBoundingClientRect()
    const menuElement = this.menuElement as HTMLElement

    let top = hostPos.top
    let left = hostPos.left

    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    // Adjust if the host element is too close to the right side of the view
    if (viewportWidth - hostPos.right < menuElement.offsetWidth) {
      left = viewportWidth - menuElement.offsetWidth - 20
    }

    // Adjust if the host element is too close to the bottom of the view
    if (viewportHeight - hostPos.bottom < menuElement.offsetHeight) {
      top = hostPos.top - menuElement.offsetHeight + 10
    }

    // Additional adjustments: if the host element is too close to the left or top of the view
    if (hostPos.left < menuElement.offsetWidth) {
      left = hostPos.right + 10 // Position to the right of the host element
    }
    if (hostPos.top < menuElement.offsetHeight) {
      top = hostPos.bottom // Position below the host element
    }

    this.renderer.setStyle(menuElement, 'position', 'fixed')
    this.renderer.setStyle(menuElement, 'top', `${top}px`)
    this.renderer.setStyle(menuElement, 'left', `${left}px`)
  }

  removeMenu() {
    if (this.menuElement) {
      this.renderer.removeChild(document.body, this.menuElement)
      this.menuElement = null
      HoverMenuDirective.activeMenuElement = null
    }
  }

  public static removeActiveMenu() {
    if (HoverMenuDirective.activeMenuElement) {
      document.body.removeChild(HoverMenuDirective.activeMenuElement)
      HoverMenuDirective.activeMenuElement = null
    }
  }

  ngOnDestroy() {
    if (this.menuElement === HoverMenuDirective.activeMenuElement) {
      HoverMenuDirective.removeActiveMenu()
    }
    if (HoverMenuDirective.mouseLeaveListener)
      HoverMenuDirective.mouseLeaveListener()
    if (HoverMenuDirective.outsideClickListener)
      HoverMenuDirective.outsideClickListener()

    if (HoverMenuDirective.mouseLeaveOutsideMenuListener)
      HoverMenuDirective.mouseLeaveOutsideMenuListener()
  }
}
