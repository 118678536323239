export class Comment {
  userId?: string
  commentId?: string
  userName?: string
  commentDate?: string
  commentText: string
  updatedAt?: string

  constructor(params?: any) {
    this.commentId = params?.commentId
    this.userId = params?.userId ?? params.UserId
    this.userName = params?.userName ?? params.UserName
    this.commentDate = params?.commentDate ?? params.CommentDate
    this.commentText = params?.commentText ?? params.CommentText
    this.updatedAt = params?.updatedAt ?? params.UpdatedAt
    this.commentId = params?.commentId ?? params.CommentId
  }
}
